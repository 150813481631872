import React, { FC, ReactElement } from 'react'
import { BokaService } from './BokaService'
import { WidgetImplementation } from 'storefront-modules/widgets'
import { CustomWidgetType, StorefrontWidget } from 'ecosystem'

type CustomComponentWidgetHandlerProps = WidgetImplementation<StorefrontWidget<CustomWidgetType>>

type CustomComponentWidget = 'STENLUNDS_BOKA_SERVICE'

export const CustomWidgetIdHandler: FC<CustomComponentWidgetHandlerProps> = ({ widget }) => {
  const widgetFactory = () => {
    const widgetDict: Record<CustomComponentWidget, ReactElement> = {
      STENLUNDS_BOKA_SERVICE: <BokaService />
    }

    return widgetDict[widget.widget.widgetId as CustomComponentWidget] ?? null
  }

  return <>{widgetFactory()}</>
}
