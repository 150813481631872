import { CircleLinkWidgetHandler, CircleLinkWidgetHandlerProps } from 'storefront-modules/widgets'

type CustomCircleLinkWidgetProps = CircleLinkWidgetHandlerProps

export const CustomCircleLinkWidget = (props: CustomCircleLinkWidgetProps) => {
  return (
    <CircleLinkWidgetHandler
      customProps={{
        itemProps: {
          w: {
            base: 'auto',
            md: '240px'
          },
          textWrapperProps: {
            spacing: 5
          },
          titleProps: {
            containerProps: {
              m: 0
            },
            isTruncated: false,
            noOfLines: undefined
          },
          textProps: {
            fontSize: '13px'
          }
        }
      }}
      {...props}
    />
  )
}
